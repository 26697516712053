/*------------- #WINDOW-POPUP --------------*/


//For open and close (onClick) window-popup used class 'js-open-popup' in element <a class='js-open-popup ..."></a>

//For the correct operation, the parent element in which the window-popup is located must have a class "has-popup"

.window-popup {
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  transform: translateY(-100px);
  transition: all 0.5s;
  visibility: hidden;
  z-index: 999999;
  margin-left: 0 !important;
}
.window-popup .content {
  opacity: 0;
  overflow: hidden;
}
.window-popup:before {
  position: absolute;
  width: 1px;
  height: 1px;
  background: $primary-color;
  content: "";
  bottom: 10px;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  -webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate(-50%, -50%);
}
.window-popup.active {
  display: block;
  background-color: $white-color;
  visibility: visible;
  transition: all 600ms;
  transform: translateY(0);
  transition: all 0.5s;
}
.window-popup.active:before {
  background: $white-color;
  border-radius: 50%;
  width: 1000px;
  height: 1000px;
  display: block;
  -webkit-transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.window-popup.active .content {
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: background-image .1s ease, all 600ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  background-image: url(../img/body-bg.png);
}


body {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(61, 67, 79, 0.5);
    opacity: 0;
    transition: opacity .3s ease;
    z-index: -999;
  }

  &.popup-active {
    overflow: hidden;

    .crumina-flying-balls [class*="col-"] {
      z-index: unset;
    }
  }

  &.body-overlay-active {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:before {
      opacity: 1;
      z-index: 200;
    }
  }
}
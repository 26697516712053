/*------------- #BLOGS --------------*/


.post {
  border-radius: 50px;
  box-shadow: 60px 0 100px rgba(61,67,79,.05);
  transition: all .3s ease;
  margin-bottom: 40px;
  position: relative;
  background-color: $white-color;
  max-width: 100%;

  .category {
    display: block;
    color: $primary-color;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }

  .avatar {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .author-prof {
    font-size: 11px;
    color: $sub-footer-text;
  }

  .author-name {
    font-size: 14px;
  }

  .divider {
    margin: 30px auto;
  }
}

.post-thumb {
  position: relative;
  border-radius: 50px 50px 0 0;
  overflow: hidden;

  img {
	display: block;
    margin: 0 auto;
  }

  iframe {
    width: 100%;
  }
}

.post__content {
  padding: 40px;
  position: relative;
}

.post__title {
  display: block;
}

.post-additional-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.align-left {
    align-items: flex-start;
  }
}

.post-comments-date-wrap {

  & > * {
    display: inline-block;
    vertical-align: middle;
    color: $icon-color;
    font-size: 14px;
    font-weight: $h-font-weight;

    & + * {
      position: relative;
      padding-left: 15px;
      margin-left: 15px;

      &:before {
        content: '';
        display: block;
        border-radius: 100%;
        width: 4px;
        height: 4px;
        background-color: $icon-color;
        position: absolute;
        top: 50%;
        margin-top: -2px;
        left: -4px;
      }
    }
  }

  a {
    color: $icon-color;

    &:hover {
      color: $primary-color;
    }
  }
}

.post__comments {
  a {
    color: $icon-color;

    &:hover {
      color: $primary-color;
    }
  }
}

/*------------- #STICKY POST --------------*/

.post.sticky-post {
  &:before {
    content: '\f005';
    font-size: 20px;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    background-color: $primary-color;
    background-size: 60%;
    display: block;
    text-align: center;
    position: absolute;
    z-index: 5;
    top: -30px;
    right: 60px;
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 100%;
    color: $white-color;
    fill: $white-color;
  }
}

.post.sticky {

  .post-thumb {
    border-radius: 50px;
  }

  .post__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
    padding: 0;
  }

  .category {
    margin-bottom: 30px;
  }

  .post__title {
    color: $white-color;
    margin-bottom: 30px;
  }

  .post-additional-info {
    flex-direction: column;
  }

  .author-block {
    text-align: left;
  }

  .post-comments-date-wrap {
    margin-bottom: 30px;
  }

  .author-name {
    color: $white-color;
  }
}


/*------------- #QUOTE POST --------------*/

.post.quote {

  blockquote {
	padding: 60px 40px 10px;
	margin: 0;
  }

  blockquote p {
    font-size: 26px;
	margin-bottom: 50px;
  }
}


/*------------- #VIDEO POST --------------*/


.post.video {
  .post-thumb {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 40px;
  }

  .overlay-standard {
	border-radius: inherit;
  }

  .video-control {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

  &.video--style2 {
	.post-thumb {
	  border-radius: 0;
	  margin: 40px -40px;
	}
  }
}


/*------------- #Slider POST --------------*/

.post.slider {
  .post-thumb {
    border-radius: 0;
    margin: 40px -40px;
  }

  .pagination-bottom-center {
	padding-bottom: 70px;
  }
}


/*------------- #Link POST --------------*/

.post.link {
  padding: 40px;

  .post-thumb {
    background-color: $primary-color;
    border-radius: 20px;
    text-align: center;
    padding: 60px;
    margin-bottom: 40px;
  }

  .post-link {
    display: inline-block;
    margin-bottom: 35px;
    width: 60px;
    height: 60px;
  }

  .post__title {
    margin-bottom: 60px;
  }

  .site-link {
    display: inline-block;
    color: $white-color;
  }

  &.link--style2 {
	.post-thumb {
	  background-color: transparent;
	  text-align: left;
	  padding: 0;
	  margin-bottom: 0;
      border-radius: 0;
	}

	.post-link {
	  position: absolute;
	  right: 20px;
	  bottom: 20px;
	  margin-bottom: 0;
	}

    .site-link {
      color: $secondary-color;
    }

    .post__title {
      margin-bottom: 30px;
    }
  }
}


/*------------- #Audio POST --------------*/

.post.audio {
  .post-thumb {
    border-radius: 0;
    margin: 40px auto;
  }
}


/*------------- #WIDE POST --------------*/

.post.post--wide {
  background-color: transparent;
  text-align: center;
  box-shadow: none;

  .post__content {
	max-width: 60%;
	margin: 0 auto;
    padding: 40px 0 20px;
  }

  .post-additional-info {
    flex-direction: column;
  }

  .post-comments-date-wrap {
    margin-bottom: 30px;
  }

  .author-block {
    text-align: left;
  }

  .post-thumb {
    border-radius: 50px;
  }

  &.video .post-thumb {
    margin-bottom: 0;
  }

  &.sticky-post {
    &:before {
      top: -30px;
      right: 50%;
      transform: translate(50%, 0);
    }
  }

  &.quote blockquote {
    padding: 100px 120px 80px;
    text-align: left;

    &:before {
      top: 60px;
      left: 70px;
    }

	&.align-center {
	  text-align: center;
	}
  }

  &.slider {
    .post-thumb {
      margin: 0;
      border-radius: 0;

      img {
        border-radius: 50px;
      }
    }
  }

  &.link {
	padding: 0;
  }

  &.link--style2 .post-thumb {
    background-color: $white-color;
    text-align: left;
    padding: 60px 100px;
    border-radius: 50px;
	margin-bottom: 40px;
  }

  &.audio .post-thumb {
	border-radius: 0;
	margin: 0;
  }
}

.post--wide-wrap {
  .divider {
	margin-bottom: 40px;
  }
}


/*------------- #POST Standard Details --------------*/


.post-standard-details {

  p {
    margin: 35px 0;
  }

  .post__title {
    margin: 35px 0;
  }

  .post-additional-info {
    margin-bottom: 35px;
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	margin-bottom: 35px;
  }

  blockquote {
	padding: 30px 80px;
  }

  .link--with-decoration {
    margin-bottom: 0;
  }
}

.blog-details-author {
  background-color: $white-color;
  border-radius: 50px;
  padding: 40px;
  box-shadow: 60px 0 100px rgba(61,67,79,.05);
  margin: 40px 0;

  .author-block {
	float: left;
	margin-right: 40px;
  }

  .avatar {
	width: 120px;
	height: 120px;
  }

  .author-prof {
	font-size: 14px;
    color: $sub-footer-text;
  }

  .author-details {
	overflow: hidden;
  }

  .author-content-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
  }

  p {
    margin: 0 0 1.5em;
  }
}


/*------------- #POST Standard Details V2 --------------*/

.post-standard-details--v2 {
  background-color: transparent;
  box-shadow: none;

  .post__content {
    padding: 0;
  }

  .comments-wrap {
    background-color: transparent;
  }

  .comments .title {
    text-align: center;
  }

  .comments__article {
    border-bottom: none;
  }

  .post-additional-info {
    flex-direction: column;

    & > * + * {
      margin-top: 40px;
    }
  }
}



/* =WordPress Core
-------------------------------------------------------------- */

.align-center,
.aligncenter {
  text-align: center;
}

.align-left{
  text-align: left;
}

.align-right {
  text-align: right;
}

img.aligncenter {
  display: block;
  margin: 20px auto;
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 40px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;

  img {
    margin: 0 auto 25px;
    border-radius: 20px;
    display: block;
  }

  &.wp-caption-text {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.wp-caption-text {
  font-size: 14px;
  font-weight: $h-font-weight;
  color: $secondary-color;

  span {
    font-weight: 400;
    color: $sub-footer-text;
  }
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption p.wp-caption-text {
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}


/*================= Responsive Mode ============*/

@media (max-width: 1024px) {
  .blog-details-author .author-block {
    float: none;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .blog-details-author {
    padding: 20px;
  }

  .blog-details-author .avatar {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 992px) {
  .post__content {
    padding: 20px;
  }

  .post.slider .post-thumb,
  .post.video.video--style2 .post-thumb {
    margin: 20px -20px;
  }
}

@media (max-width: 768px) {
  .post {
    margin-bottom: 20px;
  }

  .post .divider {
    margin: 10px auto;
  }

  .post.sticky .post-additional-info {
    display: none;
  }

  .post.link .post-link {
    width: 30px;
    height: 30px;
  }

  .post.sticky .category, .post.sticky .post-comments-date-wrap {
    margin-bottom: 0;
  }

  .post.sticky .post__title {
    margin-bottom: 0;
  }

  .post.sticky-post::before {
    font-size: 16px;
    top: -20px;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }

  .post.post--wide.quote blockquote {
    padding: 20px 40px;
  }

  .post--wide-wrap .divider {
	margin-bottom: 20px;
  }

  .post.post--wide.link--style2 .post-thumb {
	padding: 20px 40px;
  }

  .post.post--wide.sticky-post::before {
    top: -20px;
  }

  .post.post--wide .post__content {
	max-width: none;
  }

  .post.quote blockquote {
    padding: 20px 20px 10px;
  }

  .post.link {
    padding: 20px;
  }

  .post.quote blockquote p {
    font-size: 20px;
  }

  .post.link .post-thumb {
    padding: 30px;
  }

  .post-standard-details blockquote {
    padding: 30px;
  }

  .post-comments-date-wrap > * {
    font-size: 12px;
  }

  .post-standard-details {

    p {
      margin: 20px 0;
    }

    .post__title {
      margin: 20px 0;
    }

    .post-additional-info {
      margin-bottom: 20px;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 480px) {
  .post-additional-info {
	flex-direction: column;

	.post-comments-date-wrap {
	  margin-top: 10px;
	}
  }
}

@media (max-width: 380px) {
  .blog-details-author .author-content-wrap {
    flex-direction: column;
    align-items: flex-start;

    & > * + * {
      margin-top: 20px;
    }
  }
}
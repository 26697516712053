/*------------- #SOCIALS --------------*/


.socials {
  margin: 15px 0;
  padding-left: 0;

  li {
	display: inline-block;
	margin-right: 30px;
	vertical-align: middle;

	&:last-child {
	  margin-right: 0;
	}

	&:hover .woox-icon {
	  fill: $primary-color;
	  color: $primary-color;
	}
  }

  .woox-icon {
	height: 22px;
	width: 22px;
	fill: $icon-color;
	color: $icon-color;
	display: block;
	vertical-align: middle;
	position: relative;
	z-index: 2;
  }
}

.socials--double-icons {

  li {

	a {
	  display: block;
	  position: relative;
	}

	&:hover {
	  .woox-icon {
		fill: $secondary-color;
		color: $secondary-color;

		&.hover {
		  opacity: 1;
		  fill: $primary-color;
		  color: $primary-color;
		}
	  }
	}
  }

  .woox-icon {

	&.hover {
	  position: absolute;
	  top: 1px;
	  left: 1px;
	  opacity: 0;
	  transition: all .3s ease;
	  fill: $primary-color;
	  color: $primary-color;
	  z-index: 1;
	}
  }
}

.socials--small {
  .woox-icon {
	height: 20px;
	width: 20px;
  }

  li {
	display: inline-block;
	margin-right: 10px;
  }
}

.socials--colored {
  li {

	&:hover .woox-icon {
	  fill: inherit;
	  color: inherit;
	}
  }
}

.socials--with-title {
  li {
	&:first-child {
	  font-weight: $h-font-weight;
	  color: $secondary-color;
	  margin-right: 30px;
	}
  }
}


/*================= Responsive Mode ============*/

@media (max-width: 768px) {
  .socials li {
	margin-right: 15px;
  }
}

@media (max-width: 640px) {
  .socials--with-title li:first-child {
	margin-right: 10px;
  }
}
/*------------- #FORMS --------------*/


input, textarea, select, .select2 {
  padding: 14px 40px;
  border: 2px solid $grey;
  transition: all .3s ease;
  font-size: 16px;
  font-weight: $weight-black;
  color: $body-font-color;
  margin-bottom: 10px;
  border-radius: 50px;
  background-color: $white-color;
  box-shadow: 15px 0 40px 5px rgba(61, 67, 79, 0.1);
  width: 100%;
  outline: none;
  caret-color: $primary-color;

  &:focus {
    color: $body-font-color;

    & + .woox-icon {
      fill: $primary-color;
      color: $primary-color;
    }
  }

  &.input--squared {
    border-radius: 7px;
    padding: 14px 30px;
  }

  &.input--dark {
    background-color: $input-dark-bg;

	&:focus {
	  background-color: $white-color;
	}

    &.input--with-border {
      background-color: $secondary-color;
    }
  }

  &.input--with-border {
	border-color: $dark-light;

	&:focus {
	  border-color: $primary-color;
	}
  }
}

.woox-icon {
  transition: all .3s ease;
}

::-webkit-input-placeholder {color:$input-color;}
::-moz-placeholder          {color:$input-color;}/* Firefox 19+ */
:-moz-placeholder           {color:$input-color;}/* Firefox 18- */
:-ms-input-placeholder      {color:$input-color;}

/*------------- Login FORMS --------------*/


.login-form {
  padding: 40px;
  border-radius: 5px;
  box-shadow: 15px 0 40px 5px rgba(61, 67, 79, 0.08);
  text-align: center;

  .checkbox {
    margin: 1.4em 0;
    text-align: left;
  }

  .btn {
    margin-bottom: 25px;
  }

  .input-label {
    margin: 20px 0;
    font-weight: $weight-black;
    font-size: 14px;
    color: $heading-color;
    display: block;
    text-align: left;

    abbr {
      color: $red;
    }
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.form--light {
  background-color: $white-color;
}

.form--dark {
  background-color: $dark;

  a {
	color: $grey;
  }

  .input-label {
	color: $white-color;
  }

  .input--with-border {

	&:focus {
	  color: $white-color;
	}
  }

  .link--with-decoration-style2::after {
	background-color: $white-color;
  }

  .link--with-decoration-style2 {
	&:hover:after {
	  background-color: $white-color;
	}
  }

  /*------------- Styles for checkboxes in dark version*/

  .checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
    background-color: $body-font-color;
    border-color: $body-font-color;
  }

  .checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
    color: #4d5360;
  }

  .checkbox .checkbox-material .check {
    border-color: $input-color;
  }

  .checkbox input[type="checkbox"]:checked + .checkbox-material::after {
    display: none;
  }

  .checkbox input[type="checkbox"]:checked + .checkbox-material .check {
    background: $primary-color;
    border-color: $primary-color;
  }

  .checkbox input[type="checkbox"]:checked + .checkbox-material .check::before {
    color: $secondary-color;
  }

  .checkbox input[type="checkbox"][disabled] ~ .checkbox-material .check {
    background-color: $body-font-color;
    border-color: $body-font-color;
  }

  .checkbox--style4.checkbox .checkbox-material .check {
    border-color: $primary-color;
  }

  .checkbox--style4.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
    background-color: $secondary-color;
  }

  .checkbox--style4.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
    background-color: $body-font-color;
  }

  .checkbox--style4.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
	background-color: #4d5360;
  }

  /*------------- Styles for radio-buttons in dark version*/

  .radio label .circle {
	border-color: $input-color;
  }

  .radio input[type="radio"]:checked ~ .check {
	background-color: $primary-color;
	transform: scale3d(.5,.5,1);
  }

  .radio input[type="radio"]:checked ~ .circle {
	border-color: $primary-color;
    box-shadow: inset 1px 2px 0 0 transparent,1px 2px 0 0 transparent;
  }

  .radio input[type="radio"]:checked ~ .circle::before {
    display: none;
  }

  .radio input[type="radio"]:checked:disabled ~ .check {
	background-color: $body-font-color;
  }

  .radio input[type="radio"][disabled] ~ .circle {
	border-color: #4d5360;
	background-color: #4d5360;
  }

  .radio--style2.radio label .circle {
	border-color: transparent;
  }

  .radio--style2.radio input[type="radio"]:checked ~ .check {
	background-color: $secondary-color;
  }

  .radio--style2.radio input[type="radio"]:checked:disabled ~ .circle {
	border-color: #4d5360;
	background-color: #4d5360;
  }

  .radio--style2.radio input[type="radio"]:checked:disabled ~ .check {
	background-color: $body-font-color;
  }

  .radio--style5.radio input[type="radio"]:checked ~ .check {
	transform: scale3d(.3,.3,1);
  }

  .radio--style5.radio label .circle {
	background-color: #4d5360;
  }

  .radio--style5.radio input[type="radio"]:checked ~ .circle {
	background-color: transparent;
  }

  .radio--style5.radio input[type="radio"]:checked:disabled ~ .circle {
	border-color: $body-font-color;
	background-color: $body-font-color;
  }

  .radio--style5.radio input[type="radio"]:checked:disabled ~ .check {
	background-color: #4d5360;
  }
}

.input-with-icon {
  position: relative;

  .woox-icon {
    position: absolute;
    left: 40px;
    top: 18px;
    height: 20px;
    width: 20px;
    font-size: 20px;
    fill: $icon-color;
    color: $icon-color;
    z-index: 1;
    transition: all .3s ease;
  }

  input, textarea, select {
    padding-left: 80px;

    &:focus + .woox-icon {
      fill: $primary-color;
    }

	&.input--dark {
	  & + .woox-icon {
		fill: $dark-light;
		color: $dark-light;
	  }

	  &:focus + .woox-icon {
		fill: $primary-color;
		color: $primary-color;
	  }
	}
  }

  &.input-icon--right {

    .woox-icon {
      left: auto;
      right: 30px;
    }

    input, textarea, select {
      padding-left: 30px;
      padding-right: 80px;
    }
  }
}

.forgot-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6em 0;
  width: 100%;

  .checkbox {
    margin: 0;
  }
}

/*------------- Styles for inputs with label (material) --------------*/

/*https://www.creative-tim.com/product/material-kit#*/

.form-group.is-focused .checkbox label {
  color: rgba(0,0,0, 0.26);
}

.form-group label.control-label {
  line-height: 1.07143;
  margin: 0;
  font-size: 16px;
  font-weight: $weight-black;
  color: $input-color;
}

.form-group.label-floating label.control-label, .form-group.label-placeholder label.control-label {
  top: 27px;
  left: 32px;
}
.form-group.label-static label.control-label, .form-group.label-floating.is-focused label.control-label, .form-group.label-floating:not(.is-empty) label.control-label {
  top: 10px;
  font-size: 11px;
  line-height: 1.07143;
}
.form-group.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
  top: -28px;
  left: 0;
  font-size: 11px;
  line-height: 1.07143;
}

input.form-control {
  padding: 19px 30px;;
}

select.form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0;
}
.form-group.is-focused select.form-control {
  box-shadow: none;
  border-color: #D2D2D2;
}
select.form-control[multiple], .form-group.is-focused select.form-control[multiple] {
  height: 85px;
}

.input-group-btn .btn {
  margin: 0 0 7px 0;
}

.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0;
}
.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0;
}

.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-group {
  position: relative;

  label.error {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: left;
  }
}

.form-group.label-static label.control-label, .form-group.label-placeholder label.control-label, .form-group.label-floating label.control-label {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}

.form-group.label-floating label.control-label {
  will-change: left, top, contents;
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none;
}

.form-group select ~ .material-input:after {
  display: none;
}



/*------------- #SELECT2 Customization --------------*/


select, .select2 {
  background-color: $white-color;

  &:disabled {
    background-color: #f8fafc;
    box-shadow: none;
    color: $grey;
  }
}

.select2 {
  padding: 13px 40px;
}

.select2-container {
  margin-bottom: 10px;
}

.select2-container--default .select2-selection--single {
  border-color: transparent;
  background-color: transparent;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
}

.select2-container.select2-container--open .select2-selection--single .select2-selection__rendered {
  color: $body-font-color;
  transition: all .3s ease;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-bottom: 3px solid $input-color;
  border-right: 3px solid $input-color;
  content: '';
  display: block;
  height: 10px;
  pointer-events: none;
  position: absolute;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.2s ease-in-out;
  width: 10px;

  &:after {
    top: 0;
    left: 2px;
    border-bottom: 3px solid $secondary-color;
    border-right: 3px solid $secondary-color;
    content: '';
    display: block;
    height: 10px;
    pointer-events: none;
    position: absolute;
    transform-origin: 66% 66%;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
    width: 10px;
    opacity: 0;
  }
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotate(-135deg);
  border-bottom-color: $primary-color;
  border-right-color: $primary-color;

  &:after {
    opacity: 1;
  }
}

.select2.select2-container--disabled {
  background-color: #f8fafc;
  box-shadow: none;
}


/*------------- #Woox-select style-squared --------------*/

.woox--select-squared {
  border-radius: 7px;
  padding: 14px 30px;

  & + .select2 {
    border-radius: 7px;
    padding: 13px 40px;
    z-index: 5;
    position: relative;

    &.select2-container--open {
      border-radius: 7px 7px 0 0;
    }
  }

  & + .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
    display: none;
  }

  & + .select2-container--default .select2-selection--single .select2-selection__arrow:after {
    top: -4px;
    left: -40px;
    border-bottom: 0;
    border-right: 0;
    content: '';
    display: block;
    height: 20px;
    pointer-events: none;
    position: absolute;
    transition: all .2s ease-in-out;
    width: 1px;
    opacity: 1;
    background-color: #a0a9ba;
  }

  & + .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:after {
    background-color: $primary-color;
  }
}

.woox--select-squared-dropdown {
  border-radius: 7px;
  margin-top: -13px;
  z-index: 1;

  .select2-results__option[aria-selected="true"],
  .select2-results__option--highlighted[aria-selected] {
    background-color: $secondary-color;
    color: $white-color;
  }
}



/*------------- #Woox-select style-bordered --------------*/


.woox--select-bordered {
  & + .select2 {
    border: 2px solid $grey;
    border-radius: 7px;
    padding: 14px 40px;

    &.select2-container--disabled {
      background-color: #f8fafc;
      border-color: $border-color;

      .select2-selection--single .select2-selection__arrow {
        background-color: $border-color;

        b {
          border-bottom-color: $grey;
          border-right-color: $grey;
        }
      }
    }

    .select2-selection--single .select2-selection__arrow {
      right: 0;
      width: 60px;
      height: 100%;
      top: 0;
      margin-top: 0;
      background-color: $grey;
      border-radius: 0 5px 5px 0;

      b {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        border-bottom-color: $white-color;
        border-right-color: $white-color;

        &:after {
          display: none;
        }
      }
    }

    &.select2-container--open {
      border-color: $primary-color;

      .select2-selection--single .select2-selection__arrow {
        background-color: $primary-color;

        b {
          transform: translate(-50%, -50%) rotate(-135deg);
        }
      }
    }
  }
}

.woox--select-bordered-dropdown {
  border-radius: 7px;
  padding: 5px 0;

  .select2-results__option[aria-selected="true"],
  .select2-results__option--highlighted[aria-selected] {
    background-color: $white-color;
    color: $primary-color;

    &:before {
      opacity: 1;
    }
  }

  .select2-results__option {
    border-top: 1px solid $border-color;
    padding: 18px 40px;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    &:after {
      right: 50px;
    }

    &:before {
      content: '';
      display: block;
      height: 1px;
      width: calc(100% - 80px);
      bottom: -1px;
      left: 40px;
      background-color: $primary-color;
      position: absolute;
      z-index: 999;
      opacity: 0;
    }
  }
}

/*------------- Language Switcher --------------*/

.language-switcher {
  width: auto;
  min-width: 150px;

  & + .select2 {
    padding: 10px 50px 10px 30px;
    border: 2px solid $body-font-color;
    background-color: transparent;
    font-size: 14px;
    transition: none;
    width: auto;
    min-width: 150px;
    max-width: 150px;
  }

  & + .select2-container {
    margin-bottom: 0;

    &.select2-container--open {
      border-radius: 30px 30px 0 0;
      border-color: transparent;
      background-color: $white-color;
    }
  }

  & + .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 40px;
  }
}

.language-switcher-dropdown {
  border-radius: 0 0 30px 30px;
  background-color: $white-color;
  top: 0;
  padding: 0 20px 20px 20px;

  .select2-results__option {
    padding: 10px 0;
    border-top: 1px solid $border-color;
    border-radius: 0;

    &:last-child {
      padding-bottom: 0;
    }

    span {
      display: flex;
      align-items: center;

      .woox-icon {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin-right: 10px;
      }
    }

    &:after {
      display: none;
    }
  }

  .select2-results__option[aria-selected="true"],
  .select2-results__option--highlighted[aria-selected] {
    background-color: transparent;
    color: $primary-color;
  }

.select2-results > .select2-results__options {
    overflow-y: visible;
  }
}



/*------------- Contact FORMS --------------*/

.contact-form {
  box-shadow: none;
  padding: 0;

  .select2, input, select, textarea {
	margin-bottom: 40px;
  }

  button {
    margin-bottom: 0;
  }
}



/*================= Responsive Mode ============*/


@media (max-width: 768px) {
  .form-group label.control-label {
    font-size: 12px;
  }

  .login-form {
    padding: 20px;
  }

  .select2, input, select, textarea {
	font-size: 12px;
	padding: 10px 20px;
  }

  .woox--select-bordered + .select2 {
	padding: 14px 20px;
  }

  .input-with-icon .woox-icon {
    top: 10px;
  }

  .input-with-icon.input-icon--right .woox-icon {
    top: 16px;
  }

  input.form-control {
    padding: 14px 20px;
  }

  .form-group.label-floating label.control-label,
  .form-group.label-placeholder label.control-label {
    top: 17px;
    left: 23px;
  }

  .form-group.label-floating.is-focused label.control-label,
  .form-group.label-floating:not(.is-empty) label.control-label,
  .form-group.label-static label.control-label {
    top: 5px;
    left: 23px;
  }

  .language-switcher + .select2 {
	padding: 5px 50px 5px 30px;
  }
}

@media (max-width: 460px) {
  .forgot-block {
    flex-direction: column;
	align-items: flex-start;

    & > * + * {
      margin-top: 20px;
    }
  }

  textarea {
    height: 150px !important;
  }
}
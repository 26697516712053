/*------------- #ALERT MESSAGES --------------*/


.alert {
  border-radius: 30px;
  box-shadow: 15px 0 40px 0 rgba(61,67,79,.08);
  border: none;
  margin: 30px 0;
  padding: 10px 20px;
  background-color: $white-color;
  display: flex;
  align-items: center;
  position: relative;

  strong {
	margin-right: 3px;
  }

  .alert-icon {
    min-width: 40px;
    min-height: 40px;
	max-width: 40px;
	max-height: 40px;
    line-height: 40px;
    border: 3px solid;
	border-radius: 100%;
	text-align: center;
	margin-right: 20px;

    .woox-icon {
      width: 16px;
      height: 16px;
    }
  }

  .icon-close {
	margin-left: auto;

	.woox-icon {
	  width: 12px;
	  height: 12px;
	  fill: $icon-color;
	}
  }
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-notice {

  strong {
	color: $secondary-color;
  }

  .alert-icon {
	border-color: $grey-light;

	.woox-icon {
	  fill: $icon-color;
	}
  }
}

.alert-success {

  strong {
	color: $primary-color;
  }

  .alert-icon {
	border-color: $primary-color;

	.woox-icon {
	  fill: $primary-color;
	}
  }
}

.alert-info {
  strong {
	color: $blue;
  }

  .alert-icon {
	border-color: $blue;

	.woox-icon {
	  fill: $blue;
	}
  }
}

.alert-warning {
  strong {
	color: $yellow;
  }

  .alert-icon {
	border-color: $yellow;

	.woox-icon {
	  fill: $yellow;
	}
  }
}

.alert-danger {
  strong {
	color: $red;
  }

  .alert-icon {
	border-color: $red;

	.woox-icon {
	  fill: $red;
	}
  }
}


/*------------- #ALERT Bordered --------------*/

.alert--bordered {
  background-color: transparent;
  border: 3px solid;
  padding: 7px 20px;

  .alert-icon {
	box-shadow: 6px 0 13px 0 rgba(61,67,79,.08);
	border-color: transparent;
	background-color: $white-color;
  }

  &.alert-notice {
	border-color: $grey;

	.alert-icon {

	  .woox-icon {
		fill: $grey;
	  }
	}
  }

  &.alert-danger {
	border-color: $red;

	.alert-icon {

	  .woox-icon {
		fill: $red;
	  }
	}
  }

  &.alert-warning {
	border-color: $yellow;

	.alert-icon {

	  .woox-icon {
		fill: $yellow;
	  }
	}
  }

  &.alert-success {
	border-color: $primary-color;

	.alert-icon {

	  .woox-icon {
		fill: $primary-color;
	  }
	}
  }

  &.alert-info {
	border-color: $blue;

	.alert-icon {

	  .woox-icon {
		fill: $blue;
	  }
	}
  }
}


/*------------- #ALERT With BG --------------*/

.alert--with-bg {
  box-shadow: none;

  .alert-icon {
	box-shadow: 6px 0 13px 0 rgba(61,67,79,.08);
	border-color: transparent;

	.woox-icon {
	  fill: $white-color;
	}
  }

  &.alert-notice {
	background-color: $grey-light;

	.alert-icon {
	  background-color: $grey;
	}
  }

  &.alert-danger {
	background-color: #ffe5e5;

	.alert-icon {
	  background-color: $red;
	}

	.icon-close {
	  .woox-icon {
		fill: $red;
	  }
	}
  }

  &.alert-warning {
	background-color: #fff5d7;

	.alert-icon {
	  background-color: $yellow;
	}

	.icon-close {
	  .woox-icon {
		fill: $yellow;
	  }
	}
  }

  &.alert-success {
	background-color: #e0fff6;

	.alert-icon {
	  background-color: $primary-color;
	}

	.icon-close {
	  .woox-icon {
		fill: $primary-color;
	  }
	}
  }

  &.alert-info {
	background-color: #dcf8ff;

	.alert-icon {
	  background-color: $blue;
	}

	.icon-close {
	  .woox-icon {
		fill: $blue;
	  }
	}
  }
}


/*------------- #ALERT Small --------------*/


.alert--small {
  padding: 0 20px 0 0;
  font-size: 14px;

  .alert-icon {
	box-shadow: 6px 0 13px 0 rgba(61,67,79,.08);
	border-color: transparent;

	.woox-icon {
	  fill: $white-color;
	}
  }

  &.alert-notice {

	.alert-icon {
	  background-color: $grey;
	}
  }

  &.alert-danger {

	.alert-icon {
	  background-color: $red;
	}
  }

  &.alert-warning {

	.alert-icon {
	  background-color: $yellow;
	}
  }

  &.alert-success {

	.alert-icon {
	  background-color: $primary-color;
	}
  }

  &.alert-info {

	.alert-icon {
	  background-color: $blue;
	}
  }
}



/*------------- #ALERT Large --------------*/

.alert--large {
  box-shadow: 60px 0 100px 0 rgba(61, 67, 79, .05);
  padding: 60px;

  strong {
	display: block;
	color: $secondary-color;
	font-size: 22px;
  }

  .alert-icon {
	min-width: 80px;
	min-height: 80px;
	max-width: 80px;
	max-height: 80px;
	line-height: 90px;
	margin-right: 40px;
	box-shadow: 6px 0 13px 0 rgba(61,67,79,.08);
	border-color: transparent;

	.woox-icon {
	  fill: $white-color;
	  width: 28px;
	  height: 28px;
	}
  }

  .alert-content {
	font-size: 14px;
  }

  .icon-close {
	position: absolute;
	top: 40px;
	right: 40px;
  }

  &.alert-notice {

	.alert-icon {
	  background-color: $grey;
	}
  }

  &.alert-danger {

	.alert-icon {
	  background-color: $red;
	}
  }

  &.alert-warning {

	.alert-icon {
	  background-color: $yellow;
	}
  }

  &.alert-success {

	.alert-icon {
	  background-color: $primary-color;
	}
  }

  &.alert-info {

	.alert-icon {
	  background-color: $blue;
	}
  }
}


/*------------- #ALERT Large BG --------------*/

.alert--large-bg {
  box-shadow: 60px 0 100px 0 rgba(61, 67, 79, .05);
  padding: 60px;

  strong {
	display: block;
	font-size: 22px;
  }

  .alert-icon {
	margin-right: 40px;
	border-color: transparent;

	.woox-icon {
	  width: 40px;
	  height: 40px;
	}
  }

  .alert-content {
	font-size: 14px;
  }

  .icon-close {
	position: absolute;
	top: 30px;
	right: 30px;
  }

  &.alert-notice {
	background-color: $grey-light;

	.alert-icon {
	  .woox-icon {
		fill: $icon-color;
	  }
	}

	.icon-close {
	  .woox-icon {
		fill: $icon-color;
	  }
	}
  }

  &.alert-danger {
	background-color: #ffe5e5;

	strong {
	  color: $red;
	}

	.alert-icon {
	  .woox-icon {
		fill: $red;
	  }
	}

	.icon-close {
	  .woox-icon {
		fill: $red;
	  }
	}
  }

  &.alert-warning {
	background-color: #fff5d7;

	strong {
	  color: $yellow;
	}

	.alert-icon {
	  .woox-icon {
		fill: $yellow;
	  }
	}

	.icon-close {
	  .woox-icon {
		fill: $yellow;
	  }
	}
  }

  &.alert-success {
	background-color: #e0fff6;

	.alert-icon {
	  .woox-icon {
		fill: $primary-color;
	  }
	}

	.icon-close {
	  .woox-icon {
		fill: $primary-color;
	  }
	}
  }

  &.alert-info {
	background-color: #dcf8ff;

	strong {
	  color: $blue;
	}

	.alert-icon {
	  .woox-icon {
		fill: $blue;
	  }
	}

	.icon-close {
	  .woox-icon {
		fill: $blue;
	  }
	}
  }
}


/*================= Responsive Mode ============*/


@media (max-width: 1024px) {

  .alert--large .icon-close {
	top: 20px;
	right: 20px;
  }

  .alert--large-bg .icon-close {
	top: 20px;
	right: 20px;
  }

  .alert--large {
	padding: 20px;
  }

  .alert--large-bg {
	padding: 20px;
  }

  .alert--large-bg .alert-icon .woox-icon {
	width: 20px;
	height: 20px;
  }

  .alert--large-bg strong {
	font-size: 18px;
  }

  .alert--large .alert-icon {
	min-width: 60px;
	min-height: 60px;
	max-width: 60px;
	max-height: 60px;
	line-height: 65px;
	margin-right: 20px;
  }

  .alert--large .alert-icon .woox-icon {
	width: 20px;
	height: 20px;
  }
}

@media (max-width: 768px) {
  .alert {
	flex-direction: column;
	text-align: center;
  }

  .alert .icon-close {
	margin: auto;
	margin-top: 15px;
  }

  .alert--large-bg .icon-close {
	margin-top: 0;
  }

  .alert .alert-icon {
	margin-right: 0;
	margin-bottom: 20px;
  }

  .alert--large .alert-icon {
	margin-right: 0;
	margin-bottom: 20px;
  }

  .alert--large-bg .alert-icon {
	margin-right: 0;
	margin-bottom: 20px;
  }

  .alert--small {
	padding: 10px;
  }

  .alert strong {
	margin-right: 0;
	margin-bottom: 3px;
  }
}
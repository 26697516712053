/*------------- #DIVIDERS --------------*/


.divider--style1 {
  background-image: url("../img/wave1.png");
}

.divider--style2 {
  background-image: url("../img/wave2.png");
}

.divider--style3 {
  background-image: url("../img/wave3.png");
}

.divider--style4 {
  background-image: url("../img/wave4.png");
}

.divider--style5 {
  background-image: url("../img/wave5.png");
}

.divider--style6 {
  background-image: url("../img/wave6.png");
}

.divider--style7 {
  background-image: url("../img/wave7.png");
}

.divider--style8 {
  background-image: url("../img/wave8.png");
}

.divider--style9 {
  background-image: url("../img/wave9.png");
}

.divider--style10 {
  background-image: url("../img/wave10.png");
}

.divider--style11 {
  background-image: url("../img/wave11.png");
}

.divider--style12 {
  background-image: url("../img/wave12.png");
}

.divider--style13 {
  background-image: url("../img/wave13.png");
}
/*------------- #PIE-CHARTS --------------*/



.crumina-pie-chart {
  text-align: center;
}

.pie-chart {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 100%;
  border: 2px solid $skills-bordered;
  padding: 5px;
  margin: 0 auto 30px;
  color: $heading-color;

  &:after {
    content: '';
    display: block;
    width: 164px;
    height: 164px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 2px solid $skills-bordered;
    z-index: -1;
  }

  .content {
    font-size: 41px;
    font-weight: $h-font-weight;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}

.pie-chart canvas {max-width:100%}



/*------------- #PIE-CHARTS SOLID --------------*/

.pie-chart--solid {
  .pie-chart {
    border: none;
	width: 176px;
	height: 176px;
	padding: 0;

    &:after {
      width: 100%;
      height: 100%;
      border: 9px solid $secondary-color;
	  transform: none;
	  top: 6px;
	  left: 4px;
    }
  }
}

/*------------- #PIE-CHARTS LARGE --------------*/

.pie-chart--large {
  .pie-chart {
    border: none;
    padding: 0;

    &:after {
      display: none;
    }
  }
}
